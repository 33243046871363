.whats-app {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 3px 4px 3px #999;
    right: 15px; /* Change 'left' to 'right' */
    z-index: 100;
  }
  

.my-float {
    margin-top: 10px;
}
.test{
  color:#f36a86;
}